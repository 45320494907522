import { useState, useEffect } from "react";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import parse from "html-react-parser";

function gencode() {
  let a =
    "a" +
    String(Math.floor(Math.random() * 1000)) +
    "id" +
    String(Math.floor(Math.random() * 300)) +
    String(Math.floor(Math.random() * 500));
  return a;
}

const Temps = (props) => {
  const [showModal, setShowModal] = useState(false);

  const [currentTemplateId, setCurrentTemplateId] = useState("");
  const [currentTemplateName, setCurrentTemplateName] = useState("");
  const [currentTemplateContent, setCurrentTemplateContent] = useState("");

  const [saving, setSaving] = useState(false);
  const [isNew, setIsNew] = useState(false);

  function edit(id) {
    setShowModal(true);
    axios
      .post("/api/gettemp", {
        token: localStorage.getItem("session_token"),
        id: id,
      })
      .then(function (res) {
        console.log(res);
        if (res.data.status) {
          //setcurrentAudence Info
          setCurrentTemplateId(res.data.data.temp_id);
          setCurrentTemplateName(res.data.data.name);
          setCurrentTemplateContent(res.data.data.content);
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function create() {
    setIsNew(true);
    setShowModal(true);
    setCurrentTemplateId(String(gencode()));
    setCurrentTemplateName("");
    setCurrentTemplateContent("");
  }

  function save() {
    setSaving(true);
    axios
      .post("/api/createupdatetemplate", {
        token: localStorage.getItem("session_token"),
        id: currentTemplateId,
        name: currentTemplateName,
        content: currentTemplateContent,
      })
      .then(function (res) {
        console.log(res);
        if (res.data.status) {
          //setcurrentAudence Info
          props.updated();
          setSaving(false);
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    setIsNew(false);
  }

  function delTemp() {
    axios
      .post("/api/deletetemp", {
        token: localStorage.getItem("session_token"),
        id: currentTemplateId,
      })
      .then(function (res) {
        console.log(res);
        if (res.data.status) {
          //setcurrentAudence Info
          props.deleted();
          setSaving(false);
          setShowModal(false);
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function hideModal() {
    setShowModal(false);
    setCurrentTemplateId("");
    setCurrentTemplateName("");
    setCurrentTemplateContent("");
  }

  return (
    <div>
      <div className="main">
        <header className="mb-4 row m-0">
          <h2 className="title">Templates</h2>
          <button
            className="ml-auto btn-init"
            onClick={() => {
              create();
            }}
          >
            Create Template
          </button>
        </header>
        <hr />
        <div className="items row m-0 pt-3">
          {props.tList.map((temp, id) => (
            <button
              className="item"
              onClick={() => {
                edit(temp.temp_id);
              }}
              key={temp.temp_id}
            >
              <i className="fa fa-laptop-code mr-3"></i> {temp.name}
            </button>
          ))}
          {props.tList.length <= 0 ? (
            <p>
              No Templates have been added yet! Create one by clicking the Create
              Audience button
            </p>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="my-modal" hidden={showModal ? false : true}>
        <div
          className="background"
          onClick={() => {
            hideModal();
          }}
        ></div>
        <div className="inner">
          <div className="sub-inner pad-r-temp">
            <button
              className="close-btn"
              onClick={() => {
                hideModal();
              }}
            >
              <i className="fa fa-times"></i>
            </button>

            <h3 className="title mt-3">Template Name</h3>
            <input
              type="text"
              className="editable-input mb-2"
              onChange={(e) => {
                setCurrentTemplateName(e.target.value);
              }}
              value={currentTemplateName}
              placeholder="Enter template name"
            />

            <h3 className="title mt-3 mb-0">Add Template</h3>
            <small>
              this will be the body of your email it can be plain text or code
            </small>
            <textarea
              name=""
              onChange={(e) => {
                setCurrentTemplateContent(e.target.value);
              }}
              value={currentTemplateContent}
              cols="30"
              rows="10"
              className="form-control mb-3 mt-3"
              placeholder="Email content"
            ></textarea>

            <div className="row m-0">
              <button
                className="btn-del"
                onClick={() => {
                  delTemp();
                }}
                hidden={isNew ? true : false}
              >
                <i className="fa fa-trash"></i>
              </button>

              <button
                className="btn-init ml-auto"
                onClick={() => {
                  save();
                }}
                disabled={currentTemplateName.length > 0 ? false : true}
              >
                {saving ? (
                  <Spinner animation="border" size="sm" role="status"></Spinner>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
          <div className="preview-small">{parse(currentTemplateContent)}</div>
        </div>
      </div>
    </div>
  );
};

export { Temps };
