import { useState, useEffect } from "react";
import axios from "axios";

const Camps = (props) => {
  const [viewCampaignId, setViewCampaignId] = useState("");
  const [viewCampName, setViewCampName] = useState("");
  const [viewCampSubject, setViewCampSubject] = useState("");
  const [viewAudienceId, setViewAudienceId] = useState("");
  const [viewTempId, setViewTempId] = useState("");

  const [showCamp, setShowCamp] = useState(false);

  useEffect(() => {
    if (viewCampaignId.length > 0) {
      axios
        .post("/api/updatecampaign", {
          session_token: localStorage.getItem("session_token"),
          id: viewCampaignId,
          name: viewCampName,
          subject: viewCampSubject,
          audience: viewAudienceId,
          temp: viewTempId,
        })
        .then(function (res) {
          console.log(res);
          if (res.data.status) {
            //setcurrentAudence, curretTemplate and more
            //set updating false
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [viewCampName, viewCampSubject, viewAudienceId, viewTempId]);

  function editCampaign(id) {
    axios
      .post("/api/getcampign", {
        session_token: localStorage.getItem("session_token"),
        camp_id: id,
      })
      .then(function (res) {
        console.log(res);
        if (res.data.status) {
          //setcurrentAudence, curretTemplate and more
          setShowCamp(true);
          setViewCampaignId(res.data.data.camp_id);
          setViewCampName(res.data.data.name);
          setViewCampSubject(res.data.data.subject);
          setViewAudienceId(res.data.data.audience);
          setViewTempId(res.data.data.temp);
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div>
      <div className="main">
        <header className="mb-4 row m-0">
          <h2 className="title">Campaigns</h2>

          <button
            className="ml-auto btn-init"
            onClick={() => {
              props.create();
            }}
          >
            Create Campaign
          </button>
        </header>
        <div className="items row m-0 pt-3">
          {props.cList.map((campaign, id) => (
            <button
              className="item"
              key={campaign.camp_id}
              onClick={() => {
                editCampaign(campaign.camp_id);
              }}
            >
              <i className="fa fa-bullhorn mr-3"></i> {campaign.name}
            </button>
          ))}

          {props.cList.length <= 0 ? (
             <p>
              No Campaigns added yet! Create one by clicking the create campaign button
            </p>
          ) : (
           ""
          )}
          
          
        </div>
      </div>

      <div className="my-modal" hidden={showCamp ? false : true}>
        <div
          className="background"
          onClick={() => {
            setShowCamp(false);
            props.onChange(true);
          }}
        ></div>
        <div className="inner">
          <div className="sub-inner">
            <div className="ml-auto mr-auto pt-2">
              <div className="compose">
                <div className="row">
                  <div className="mb-4 col-lg-auto">
                    <h4 className=" mr-3 small-title">Campaign Name</h4>
                    <input
                      type="text"
                      className="editable-input"
                      placeholder="Campaign Name"
                      onChange={(e) => {
                        setViewCampName(e.target.value);
                      }}
                      value={viewCampName}
                    />
                  </div>

                  <div className="mb-4 col-lg-auto">
                    <h4 className=" mr-3 small-title">Subject</h4>
                    <input
                      type="text"
                      className="editable-input"
                      placeholder="Email Subject"
                      onChange={(e) => {
                        setViewCampSubject(e.target.value);
                      }}
                      value={viewCampSubject}
                    />
                  </div>
                </div>

                <div className="ver mb-1">
                  <h4 className=" mr-3 small-title">Select Audience List</h4>
                </div>
                {props.aList.map((list, id) => (
                  <button
                    className={
                      viewAudienceId === list.list_id
                        ? " selectable selected"
                        : "selectable"
                    }
                    onClick={() => {
                      setViewAudienceId(list.list_id);
                    }}
                    key={list.list_id}
                  >
                    {list.name}
                  </button>
                ))}
              </div>

              <div>
                <div className="ver mb-1 mt-4">
                  <h4 className="mr-3 small-title">Select Template</h4>
                </div>
                {props.tList.map((temp, id) => (
                  <button
                    className={
                      viewTempId === temp.temp_id
                        ? " selectable selected"
                        : "selectable"
                    }
                    onClick={() => {
                      setViewTempId(temp.temp_id);
                    }}
                    key={temp.temp_id}
                  >
                    {temp.name}
                  </button>
                ))}
              </div>

              <div className="row m-0">
                <button
                  className="btn-del mt-5 font-weight-bold"
                  onClick={() => {
                    //preview this campaign
                    //delete
                    props.delete(viewCampaignId);
                    setShowCamp(false);
                  }}
                >
                  <i className="fa fa-trash"></i>
                </button>

                <button
                  className="btn-init mt-5 ml-auto"
                  onClick={() => {
                    //preview this campaign
                    props.preview({
                      name: viewCampName,
                      subject: viewCampSubject,
                      audience: viewAudienceId,
                      temp: viewTempId,
                    });
                  }}
                >
                  Continue to Preview{" "}
                  <i className="fa fa-long-arrow-alt-right ml-2"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Camps };
