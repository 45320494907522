import { useState, useEffect } from "react";
import "./App.css";
import { Camps } from "./camps";
import { Audiences } from "./audiences";
import { Temps } from "./temps";
import { Preview } from "./preview";
import axios from "axios";

function gencode() {
  let a =
    "bid" +
    String(Math.floor(Math.random() * 1000)) +
    String(Math.floor(Math.random() * 300)) +
    String(Math.floor(Math.random() * 500));
  return a;
}

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [currentView, setCurrentView] = useState("new");

  const [token, setToken] = useState("");

  const [campaignList, setCampaignsList] = useState([]);
  const [audienceList, setAudienceList] = useState([]);
  const [templateList, setTemplateList] = useState([]);

  const [currentAudience, setCurrentAudience] = useState("");
  const [currentTemplate, setCurrentTemplate] = useState("");

  const [campName, setCampName] = useState("");
  const [campSubject, setCampSubject] = useState("");

  const [loginError, setLoginError] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("session_token")) {
      axios
        .post("/api/loggedin", {
          session_token: localStorage.getItem("session_token"),
        })
        .then(function (res) {
          console.log(res);
          if (res.data.verify) {
            setToken(localStorage.getItem("session_token"));
            setLoggedIn(true);
            fetchAll(localStorage.getItem("session_token"));
          } else {
            setLoggedIn(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          fetchAll(localStorage.getItem("session_token"));
        });
    }
  }, []);

  useEffect(() => {
    if (loggedIn) {
      fetchAll(localStorage.getItem("session_token"));
    }
  }, [currentView]);

  function fetchAll(session_token) {
    console.log(session_token);
    axios
      .post("/api/camps", {
        token: session_token,
      })
      .then(function (response) {
        console.log(response);
        if (response.data.status) {
          setCampaignsList(response.data.data);
        } else {
          setLoggedIn(false);
        }
      })
      .catch(function (error) {
        setLoggedIn(false);
      });

    axios
      .post("/api/audiences", {
        token: session_token,
      })
      .then(function (response) {
        console.log(response);
        if (response.data.status) {
          setAudienceList(response.data.data);
        } else {
          setLoggedIn(false);
        }
      })
      .catch(function (error) {
        setLoggedIn(false);
      });

    axios
      .post("/api/temps", {
        token: session_token,
      })
      .then(function (response) {
        console.log(response);
        if (response.data.status) {
          setTemplateList(response.data.data);
        } else {
          setLoggedIn(false);
        }
      })
      .catch(function (error) {
        setLoggedIn(false);
      });
  }

  function auth() {
    axios
      .post("/api/login", { username: username, password: password })
      .then(function (res) {
        console.log(res);
        if (res.data.status) {
          //store token to localStorage
          localStorage.setItem("session_token", res.data.session_token);
          setLoggedIn(true);
          fetchAll(res.data.session_token);
        } else {
          setLoggedIn(false);
          setLoginError(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function newCampaign() {
    axios
      .post("/api/newcampaign", {
        token: token,
        id: String(gencode()),
        name: campName,
        subject: campSubject,
        audience: audienceList.filter((li) => li.list_id === currentAudience)[0]
          .list_id,
        temp: templateList.filter((te) => te.temp_id === currentTemplate)[0]
          .temp_id,
      })
      .then(function (res) {
        console.log(res);
        if (res.data.status) {
          setCurrentView("preview");
          setCampName("")
          setCampSubject("")
        } else {
        }
      })
      .catch(function (error) {
        //setLoggedIn(false);
      });
  }

  function campDelete(id) {
    axios
      .post("/api/delcamp", {
        token: token,
        id: id,
      })
      .then(function (res) {
        console.log(res);
        if (res.data.status) {
          try {
            fetchAll(token);
          } catch {
            setLoggedIn(false);
          }
        } else {
          //show error
        }
      })
      .catch(function (error) {
        //setLoggedIn(false);
      });
  }

  /* campaigns */
  return (
    <div className="App">
      {loggedIn ? (
        <div>
          <nav className="nav-main">
            <div>
              <button
                className={currentView === "new" ? "active" : ""}
                onClick={() => {
                  setCurrentView("new");
                }}
                data-toggle="tooltip"
                data-placement="right"
                title="New Campaign"
              >
                <i className="fa fa-pen"></i>
              </button>
              <button
                className={currentView === "camps" ? "active" : ""}
                onClick={() => {
                  setCurrentView("camps");
                }}
                data-toggle="tooltip"
                data-placement="right"
                title="Campaigns"
              >
                <i className="fa fa-bullhorn"></i>
              </button>
              <button
                className={currentView === "audiences" ? "active" : ""}
                onClick={() => {
                  setCurrentView("audiences");
                }}
                data-toggle="tooltip"
                data-placement="right"
                title="Audiences"
              >
                <i className="fa fa-users"></i>
              </button>
              <button
                className={currentView === "temps" ? "active" : ""}
                onClick={() => {
                  setCurrentView("temps");
                }}
                data-toggle="tooltip"
                data-placement="right"
                title="Templates"
              >
                <i className="fa fa-laptop-code"></i>
              </button>
              {/* 
              <button
                data-toggle="tooltip"
                data-placement="right"
                title="Logout"
              >
                <i className="fa fa-sign-out-alt"></i>
              </button> 
              */}
            </div>
          </nav>

          <div className="main" hidden={currentView === "new" ? false : true}>
            <header className="ver">
              <h2 className="title">New Campaign</h2>
            </header>
            <hr />
            <div className="col-lg-8 ml-auto mr-auto pt-4">
              <div className="compose">
                <div className="row">
                  <div className="mb-4 col-lg-auto">
                    <h4 className="font-weight-bold mr-3 small-title">
                      Campaign Name
                    </h4>
                    <input
                      type="text"
                      className="editable-input"
                      placeholder="Campaign Name"
                      onChange={(e) => {
                        setCampName(e.target.value);
                      }}
                      value={campName}
                    />
                  </div>

                  <div className="mb-4 col-lg-auto">
                    <h4 className="font-weight-bold mr-3 small-title">
                      Subject
                    </h4>
                    <input
                      type="text"
                      className="editable-input"
                      placeholder="Email Subject"
                      onChange={(e) => {
                        setCampSubject(e.target.value);
                      }}
                      value={campSubject}
                    />
                  </div>
                </div>

                <div className="ver mb-1 mt-4">
                  <h4 className="font-weight-bold mr-3 small-title">
                    Select Audience List
                  </h4>
                </div>
                {audienceList.map((list, id) => (
                  <button
                    className={
                      currentAudience === list.list_id
                        ? " selectable selected"
                        : "selectable"
                    }
                    onClick={() => {
                      setCurrentAudience(list.list_id);
                    }}
                    key={list.list_id}
                  >
                    {list.name}
                  </button>
                ))}
              </div>

              <div>
                <div className="ver mb-1 mt-5">
                  <h4 className="font-weight-bold mr-3 small-title">
                    Select Template
                  </h4>
                </div>
                {templateList.map((temp, id) => (
                  <button
                    className={
                      currentTemplate === temp.temp_id
                        ? " selectable selected"
                        : "selectable"
                    }
                    onClick={() => {
                      setCurrentTemplate(temp.temp_id);
                    }}
                    key={temp.temp_id}
                  >
                    {temp.name}
                  </button>
                ))}
              </div>

              <div className="row m-0 pt-5">
                <button
                  className="btn-init mt-5 ml-auto"
                  onClick={() => {
                    newCampaign();
                  }}
                  disabled={
                    currentAudience.length > 0 &&
                    currentTemplate.length > 0 &&
                    campName.length > 0
                      ? false
                      : true
                  }
                >
                  Continue to Preview <i className="fa fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>

          {currentView === "camps" ? (
            <Camps
              cList={campaignList}
              aList={audienceList}
              tList={templateList}
              onChange={() => {
                fetchAll(localStorage.getItem("session_token"));
              }}
              preview={(camp) => {
                setCampName(camp.name);
                setCampSubject(camp.subject);
                setCurrentAudience(camp.audience);
                setCurrentTemplate(camp.temp);

                setCurrentView("preview");
              }}
              create={() => {
                setCurrentView("new");
              }}
              delete={(id) => {
                campDelete(id);
              }}
            />
          ) : (
            ""
          )}
          {currentView === "audiences" ? (
            <Audiences
              list={audienceList}
              updated={() => {
                token.length > 0 ? fetchAll(token) : setLoggedIn(false);
              }}
            />
          ) : (
            ""
          )}
          {currentView === "temps" ? (
            <Temps
              tList={templateList}
              updated={() => {
                token.length > 0 ? fetchAll(token) : setLoggedIn(false);
              }}
              deleted={() => {
                token.length > 0 ? fetchAll(token) : setLoggedIn(false);
              }}
            />
          ) : (
            ""
          )}
          {currentView === "preview" ? (
            <Preview
              cList={campaignList}
              aList={audienceList}
              tList={templateList}
              name={campName}
              subject={campSubject}
              aid={currentAudience}
              tid={currentTemplate}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="login">
          <div className="inner">
            <h2 className="title-min m-0">
              <i className="fa fa-envelope"></i> Blaster{" "}
            </h2>

            {loginError ? (
              <p className="text-danger mb-2">
                Your username or password is incorrect please try again
              </p>
            ) : (
              ""
            )}
            <input
              type="text"
              placeholder="Username"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              value={username}
            />
            <input
              type="password"
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
            />

            <button className="btn-init" onClick={() => auth()}>
              Sign In
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
