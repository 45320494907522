import axios from "axios";
import { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";

import parse from "html-react-parser";

const Preview = (props) => {
  console.log(props, "<<<<<<<<<<<<<<<<<<<<<<<<<<<<");

  const [from, setFrom] = useState("Mark Casey SEO");

  //final template to be sent
  const [content, setContent] = useState(
    props.tList.filter((temp) => temp.temp_id === props.tid)[0].content
  );

  const [name, setName] = useState(props.name);
  const [subject, setSubject] = useState(props.subject);
  const [audienceId, setAudienceId] = useState(props.aid);

  const [demoEmail, setDemoEmail] = useState("");

  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const [sendingToAll, setSendingToAll] = useState(false);
  const [sendingToAllDone, setSendingToAllDone] = useState(false);

  const [error, setError] = useState(false);

  function sendDemo() {
    //axios send to email
    setSending(true);
    setSent(false);
    axios
      .post("/api/demo", {
        session_token: localStorage.getItem("session_token"),
        from: from,
        to: demoEmail,
        subject: subject,
        //audience: props.tList.filter((lis) => lis.list_id === audienceId)[0].list,
        content: content,
      })
      .then(function (res) {
        console.log(res);
        if (res.data.status) {
          //setcurrentAudence, curretTemplate and more
          //set updating false
          setSending(false);
          setSent(true);
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function sendToAll() {
    //start blast email
    setSendingToAll(true);
    setSendingToAllDone(false);

    axios
      .post("/api/startblasting", {
        token: localStorage.getItem("session_token"),
        from: from,
        audience: props.aList.filter((list) => list.list_id === audienceId),
        subject: subject,
        //audience: props.tList.filter((lis) => lis.list_id === audienceId)[0].list,
        content: content,
      })
      .then(function (res) {
        console.log(res);
        if (res.data.status) {
          //setcurrentAudence, curretTemplate and more
          //set updating false
          setSendingToAll(false);
          setSendingToAllDone(true);
        } else {
          setError(true);
        }
      })
      .catch(function (error) {
        setError(true);
      });
  }

  return (
    <div>
      <div className="main">
        <header className="row m-0">
          <h2 className="title">Preview {name}</h2>
        </header>
        <hr />

        <div className="col-lg-5 m-0">
          <small className="text-danger" hidden={error ? false : true}>
            oops! something went wrong Please refresh the page and try again!
          </small>

          <p>From (Sender)</p>
          <input
            type="email"
            className="editable-input mt-0 mb-3"
            placeholder="From Text"
            onChange={(e) => {
              setFrom(e.target.value);
            }}
            value={from}
            required
          />

          <p>Override Email Content</p>
          <textarea
            name=""
            id=""
            rows="5"
            className="form-control mb-2"
            placeholder="Email Content"
            onChange={(e) => {
              setContent(e.target.value);
            }}
            value={content}
          ></textarea>
          <small>
            Please make sure the template is correct by sending a demo
          </small>
          <br />

          {sending ? (
            <Spinner animation="border" size="sm" role="status"></Spinner>
          ) : (
            ""
          )}

          {sent ? (
            <small>
              <i className="fa fa-check"></i> Demo Email Sent to {demoEmail}
            </small>
          ) : (
            ""
          )}
          <form
            className="ver demo-email mb-5"
            onSubmit={(e) => {
              e.preventDefault();
              sendDemo();
            }}
          >
            <input
              type="email"
              className="editable-input"
              placeholder="Your Email"
              onChange={(e) => {
                setDemoEmail(e.target.value);
              }}
              value={demoEmail}
              required
            />
            <button className="btn-init mt-3" type="submit">
              Send Demo
            </button>
          </form>
          <button
            className="btn-init  col-lg-12"
            onClick={() => {
              sendToAll();
            }}
          >
            {sendingToAll ? (
              <Spinner animation="border" size="sm" role="status"></Spinner>
            ) : (
              ""
            )}

            {sendingToAllDone ? (
              <font>
                <i className="fa fa-check-circle"></i> Sent! Click Here to Send
                Again
              </font>
            ) : (
              ""
            )}

            {sendingToAllDone === false && sendingToAll === false ? (
              <font>
                Send To Audience <i className="fa fa-paper-plane"></i>
              </font>
            ) : (
              ""
            )}
          </button>
        </div>
      </div>

      <div className="preview-final">{parse(content)}</div>
    </div>
  );
};

export { Preview };
