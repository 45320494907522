import { useState, useEffect } from "react";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

function gencode() {
  let a =
    "a" +
    String(Math.floor(Math.random() * 1000)) +
    "id" +
    String(Math.floor(Math.random() * 300)) +
    String(Math.floor(Math.random() * 500));
  return a;
}

const Audiences = (props) => {
  const [showList, setShowList] = useState(false);

  const [currentListId, setCurrentListId] = useState("");
  const [currentListName, setCurrentListName] = useState("");
  const [currentMembers, setCurrentMembers] = useState([]);

  const [saving, setSaving] = useState(false);

  const [isNew, setIsNew] = useState(false);

  function edit(id) {
    setIsNew(false);
    setShowList(true);
    axios
      .post("/api/getaudience", {
        token: localStorage.getItem("session_token"),
        id: id,
      })
      .then(function (res) {
        console.log(res);
        if (res.data.status) {
          //setcurrentAudence Info
          setCurrentListId(res.data.data.list_id);
          setCurrentListName(res.data.data.name);
          setCurrentMembers(JSON.parse(res.data.data.list));
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function create() {
    setIsNew(true);
    setShowList(true);
    setCurrentListId(String(gencode()));
    setCurrentListName("");
    setCurrentMembers([]);
  }
  /* 
  function delEmail(member) {
    var arr = [...currentMembers];
    var index = arr.indexOf(member);
    if (index !== -1) {
      arr.splice(index, 1);
      setCurrentMembers(arr);
    }
  } */

  function addEmails(tarea) {
    let arr = tarea.split("\n").filter((each) => each.length > 0);
    console.log(arr);
    let final = [];
    arr.forEach((emailwithname) => {
      if (emailwithname.includes("\t")) {
        final.push({
          email: emailwithname.split("\t")[0],
          name: emailwithname.split("\t")[1],
        });
      } else {
        final.push({
          email: emailwithname,
          name: "",
        });
      }
    });
    console.log(final);
    setCurrentMembers(final);
  }

  function save() {
    setSaving(true);
    axios
      .post("/api/createupdateaudience", {
        token: localStorage.getItem("session_token"),
        id: currentListId,
        name: currentListName,
        members: currentMembers,
      })
      .then(function (res) {
        console.log(res);
        if (res.data.status) {
          //setcurrentAudence Info
          props.updated();
          setSaving(false);
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    setIsNew(false);
    setShowList(false);
  }

  function deleteAudience() {
    axios
      .post("/api/deleteaudience", {
        token: localStorage.getItem("session_token"),
        id: currentListId,
      })
      .then(function (res) {
        console.log(res);
        if (res.data.status) {
          //setcurrentAudence Info
          props.updated();
          setShowList(false);
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function hideModal() {
    setShowList(false);
    setIsNew(false);
    setCurrentListId("");
    setCurrentListName("");
    setCurrentMembers([]);
  }

  return (
    <div className="main">
      <header className="mb-4 row m-0">
        <h2 className="title">Audiences</h2>

        <button
          className="ml-auto btn-init"
          onClick={() => {
            create();
          }}
        >
          Create Audience
        </button>
      </header>
      <hr />
      <div className="items row m-0 pt-3">
        {props.list.map((list, id) => (
          <button
            className="item row ml-0 mr-0 mr-3"
            onClick={() => {
              edit(list.list_id);
            }}
          >
            <i className="fa fa-clipboard-list mr-2"></i>
            {list.name}
            <button className="badger ml-auto">
              {JSON.parse(list.list).length}
            </button>
          </button>
        ))}

        {props.list.length <= 0 ? (
          <p>
            No Audience has been added yet! Create one by clicking the Create
            Audience button
          </p>
        ) : (
          ""
        )}
      </div>

      <div className="my-modal" hidden={showList ? false : true}>
        <div
          className="background"
          onClick={() => {
            hideModal();
          }}
        ></div>
        <div className="inner">
          <div className="sub-inner">
            <button
              className="close-btn"
              onClick={() => {
                hideModal();
              }}
            >
              <i className="fa fa-times"></i>
            </button>

            <h3 className="title mt-3">List Name</h3>
            <input
              type="text"
              className="editable-input mb-2"
              onChange={(e) => {
                setCurrentListName(e.target.value);
              }}
              value={currentListName}
              placeholder="Audience name"
            />

            <h3 className="title mt-3 mb-0">Add audience members</h3>
            <small className="small">
              select a single column of emails from google sheets and paste or
              press Enter after entering each email
            </small>
            <textarea
              cols="30"
              rows="3"
              onChange={(e) => {
                addEmails(e.target.value);
              }}
              className="form-control mb-3 mt-3"
              placeholder="example@gmail.com
example2@gmail.com 
example3@gmail.com"
            ></textarea>

            <h3 className="title mt-3">Audience members</h3>
            <div className="emails">
              {currentMembers.map((member, id) => (
                <button
                  className="email"
                  onClick={() => {
                    alert(
                      "you cant remove individual emails. to remove an email you will have to remove the email from the spread sheet and add All the email's to the list again!"
                    );
                    /* delEmail(member.email); */
                  }}
                  key={id}
                >
                  {member.email} {/* <i className="fa fa-times-circle"></i> */}
                </button>
              ))}
            </div>
            <div className="ver">
              {/* delete btn */}
              <button
                className="btn-del"
                onClick={() => {
                  deleteAudience();
                }}
                hidden={isNew > 0 ? true : false}
              >
                <i className="fa fa-trash"></i>
              </button>
              <button
                className="btn-init"
                onClick={() => {
                  save();
                }}
                disabled={currentListName.length > 0 ? false : true}
              >
                {" "}
                {saving ? (
                  <Spinner animation="border" size="sm" role="status"></Spinner>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Audiences };
